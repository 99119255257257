export const RATE_CHART_REGIONS = {
  ARGENTINA: 'Argentina',
  BRAZIL: 'Brazil',
  CHILE: 'Chile',
  COLOMBIA: 'Colombia',
  EGYPT: 'Egypt',
  FRANCE: 'France',
  GERMANY: 'Germany',
  INDIA: 'India',
  INDONESIA: 'Indonesia',
  ISRAEL: 'Israel',
  ITALY: 'Italy',
  MALAYSIA: 'Malaysia',
  MEXICO: 'Mexico',
  NETHERLANDS: 'Netherlands',
  NIGERIA: 'Nigeria',
  PAKISTAN: 'Pakistan',
  PERU: 'Peru',
  RUSSIA: 'Russia',
  SAUDI_ARABIA: 'Saudi Arabia',
  SOUTH_AFRICA: 'South Africa',
  SPAIN: 'Spain',
  TURKEY: 'Turkey',
  UNITED_ARAB_EMIRATES: 'United Arab Emirates',
  UNITED_KINGDOM: 'United Kingdom',
  NORTH_AMERICA: 'North America',
  REST_OF_AFRICA: 'Rest of Africa',
  REST_OF_ASIA_PACIFIC: 'Rest of Asia Pacific',
  REST_OF_CENTRAL_EASTERN_EUROPE: 'Rest of Central & Eastern Europe',
  REST_OF_LATIN_AMERICA: 'Rest of Latin America',
  REST_OF_MIDDLE_EAST: 'Rest of Middle East',
  REST_OF_WESTERN_EUROPE: 'Rest of Western Europe',
  OTHER: 'Other',
};
