/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import RateChartDetails from './RateChartDetails';

const ConfigureMarkup = () => {
  const [showConfigureMarkupModal, setShowConfigureMarkupModal] =
    useState(false);

  const renderConfigureMarkupModal = () => {
    const handleClose = () => setShowConfigureMarkupModal(false);

    return (
      <Modal open={showConfigureMarkupModal} onClose={handleClose}>
        <Box
          css={css`
            width: 100%;
            max-width: 700px;
            height: auto;
            max-height: 550px;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 50%;
            border-radius: 8px;
            overflow: hidden;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            outline: none;

            &:focus-within {
              outline: none;
            }
          `}
        >
          <RateChartDetails onClose={handleClose} />
        </Box>
      </Modal>
    );
  };

  const handleConfigureClick = () => setShowConfigureMarkupModal(true);

  return (
    <Box
      css={css`
        width: 100%;
        background: #fff;
        border: 1px solid #e0e0e9;
        border-radius: 8px;
        padding: 12px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <Typography
        css={css`
          font-size: 14px;
          font-weight: 600;
          color: #2d2d2d;
        `}
      >
        Configure markup charges
      </Typography>
      <Button
        variant="text"
        css={css`
          font-size: 14px;
          font-weight: 600;
          color: #3f906d;
          text-decoration: underline;

          &:hover {
            text-decoration: underline;
          }
        `}
        onClick={handleConfigureClick}
      >
        Configure
      </Button>
      {renderConfigureMarkupModal()}
    </Box>
  );
};

export default ConfigureMarkup;
