import { StatusBadgeTypes } from '../../../components/badge';

export interface ProflieAPIResponseType {
  subscribedPlan: {
    canMakeNextPayment: boolean;
    displayName: string;
    numUsers: number;
    renewalDate: string;
    id?: string;
  };
  org: {
    id: string;
    dateCreated: string;
    dateUpdated: string;
    name: string;
    domain: string;
    ownerId: string;
    pictureUrl: string;
    phone: string;
    description: string;
    businessTimings: string;
    countryCode: string;
    state: string;
    city: string;
    email: string;
    website: string;
    website2: string;
    category: string;
    balanceCredits: string;
    balanceAmount: string;
    walletCurrency: string;
    billingAddress: any;
    taxType: string;
    language: string;
    planId: string;
  };
  waba: {
    totalWabaCount: number;
  };
  wallet: {
    balance: number;
    currencyCode: string;
  };
  customerLoginUrl: string;
  ownerName: string;
  billingDetails: {
    billingAddress: string;
    billingTaxNumber?: string | null;
    pinCode: string;
  };
}

export interface WabaListAPIResponseItemType {
  id: string;
  wabaPhoneName: string;
  wabaNumber: string;
  assignedAgentCount: number;
  dateAdded: string;
  dateCreated: string;
  imageUrl: string;
  status: StatusBadgeTypes;
}

export type WabaListAPIResponseType = WabaListAPIResponseItemType[];

export enum CONVERSION_TYPE_LABELS {
  MARKETING = 'Marketing',
  UTILITY = 'Utility',
  AUTHENTICATION = 'Authentication',
  AUTHENTICATION_INTERNATIONAL = 'Authentication International',
}

export enum CONVERSION_TYPE_VALUES {
  MARKETING = 'marketing',
  UTILITY = 'utility',
  AUTHENTICATION = 'authentication',
  AUTHENTICATION_INTERNATIONAL = 'authentication_international',
}

export interface RateChartDetailsResponse {
  dtPrices: {
    marketing: number;
    utility: number;
    authentication: number;
    authentication_international: number;
  };
  userPrices: {
    marketing: number;
    utility: number;
    authentication: number;
    authentication_international: number;
  };
}
