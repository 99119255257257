import { atom } from 'jotai';
import { createRequestAtom } from '../../../utils/atoms/request-atom';
import { ProflieAPIResponseType, RateChartDetailsResponse } from '../types';

export const orgProfileAtom = createRequestAtom<ProflieAPIResponseType>({
  initialLoading: false
});

export const wabaUserSearchQueryAtom = atom<string>('');

export const rateChartDetailsAtom =
  createRequestAtom<RateChartDetailsResponse>();

export const rateChartSelectedRegionAtom = atom<string>('India');
