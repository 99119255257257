/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Tab, Tabs } from '@mui/material';
import React, { useMemo } from 'react';
import PlanDetailsInput from './PlanDetailsInput';
import { getPlanPricingAPI } from '../request/plans';
import {
  PlanDetailsInputPropsPlanDetailsType,
  PlansPricingAPIResponseType,
  PlanType,
  PriceType,
} from '../types';
import useQuery from '../../../hooks/useQuery';
import { planPricingAtom } from '../state';
import Loader from '../../../components/loader';

type OrganisedPlanType = PlanType & PriceType;

interface OrganizedPricePlanType {
  monthly: OrganisedPlanType[];
  quarterly: OrganisedPlanType[];
  yearly: OrganisedPlanType[];
}
// Function to organize the plans by their period
const organizePlansByPeriod = (plans: PlanType[]) => {
  const organizedPlans: OrganizedPricePlanType = {
    monthly: [],
    quarterly: [],
    yearly: [],
  };

  plans.forEach((plan) => {
    plan.price.forEach((price) => {
      if (price.unit === 'month' && price.period === 1) {
        organizedPlans.monthly.push({ ...plan, ...price });
      } else if (price.unit === 'month' && price.period === 3) {
        organizedPlans.quarterly.push({ ...plan, ...price });
      } else if (price.unit === 'year' && price.period === 1) {
        organizedPlans.yearly.push({ ...plan, ...price });
      }
    });
  });

  return organizedPlans;
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      css={css`
        margin-top: 20px;
      `}
    >
      {value === index && children}
    </div>
  );
}

const PlanTabs: React.FC<{}> = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {
    requestData: { data, loading },
  } = useQuery<PlansPricingAPIResponseType>({
    requestAtom: planPricingAtom,
    queryFunction: getPlanPricingAPI,
    cacheData: false,
  });

  const organizedPlans: OrganizedPricePlanType = useMemo(() => {
    if (data?.plans) {
      return organizePlansByPeriod(data.plans);
    }
    return {
      monthly: [],
      quarterly: [],
      yearly: [],
    };
  }, [data]);

  const tabs = [
    { label: 'MONTHLY', plans: organizedPlans.monthly },
    { label: 'QUARTERLY', plans: organizedPlans.quarterly },
    { label: 'YEARLY', plans: organizedPlans.yearly },
  ];

  if (loading) {
    return (
      <Box mt={'100px'} width={'600px'}>
        <Loader size={32} secondary={'Loading...'} />
      </Box>
    );
  }

  return (
    <div>
      <Tabs
        value={value}
        css={css`
          margin-top: 20px;
          border-bottom: 1px solid #e0e0e9;
        `}
        onChange={handleChange}
        aria-label="plan tabs"
        TabIndicatorProps={{ style: { backgroundColor: '#3F906D' } }}
      >
        {tabs
          .filter((tab) => tab.plans.length > 0)
          .map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              {...a11yProps(index)}
              sx={{ '&.Mui-selected': { color: '#3F906D' } }}
            />
          ))}
      </Tabs>
      {tabs
        .filter((tab) => tab.plans.length > 0)
        .map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            <PlanDetailsInput
              key={index}
              planDetails={tab.plans as PlanDetailsInputPropsPlanDetailsType}
            />
          </CustomTabPanel>
        ))}
    </div>
  );
};

export default PlanTabs;
