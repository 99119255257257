import { FC } from 'react';
import { IconProps } from '../app/typings';

const MinusIcon: FC<IconProps> = ({ color = '#fff', style, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20px"
      viewBox="0 -960 960 960"
      width="20px"
      fill={color}
    >
      <path d="M200-440v-80h560v80H200Z" fill={color} />
    </svg>
  );
};

export default MinusIcon;
