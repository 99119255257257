import requestConfig from '../../../../config/request';
import { post } from '../../../infra/rest';

const getOtpWithNumber = async ({
  phoneNumber,
  countryCode,
  deviceToken,
  service,
  productType,
  nonce,
}: {
  phoneNumber: string;
  countryCode: string;
  deviceToken: string;
  service: string;
  productType: string;
  nonce: string;
}) => {
  return post(
    `${requestConfig.smsServiceUrl}/v1/sms/send-otp`,
    {
      body: JSON.stringify({
        phoneNumber,
        countryCode,
        deviceToken,
        service,
        productType,
        nonce,
      }),
    },
    undefined,
    undefined,
    true
  );
};

const resendOtpWithNumber = async ({
  phoneNumber,
  countryCode,
  deviceToken,
  service,
  productType,
  nonce,
}: {
  phoneNumber: string;
  countryCode: string;
  deviceToken: string;
  service: string;
  productType: string;
  nonce: string;
}) => {
  return post(
    `${requestConfig.smsServiceUrl}/v1/sms/resend-otp`,
    {
      body: JSON.stringify({
        phoneNumber,
        countryCode,
        deviceToken,
        service,
        productType,
        nonce,
      }),
    },
    undefined,
    undefined,
    true
  );
};

const generateNonce = async ({
  currentTimeStamp,
  deviceToken,
  service,
  productType,
  phoneNumber,
  countryCode,
}: {
  currentTimeStamp: string;
  deviceToken: string;
  service: string;
  productType: string;
  phoneNumber: string;
  countryCode: string;
}) => {
  return post<{ nonce: string }>(
    `${requestConfig.smsServiceUrl}/v1/sms/nonce`,
    {
      body: JSON.stringify({
        currentTimeStamp,
        deviceToken,
        service,
        productType,
        phoneNumber,
        countryCode,
      }),
    },
    undefined,
    undefined,
    true
  );
};

export { getOtpWithNumber, resendOtpWithNumber, generateNonce };
