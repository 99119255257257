type RequestConfig = {
  baseUrl: string;
  smsServiceUrl: string;
  qsApiUrl: string;
};

let requestConfig: RequestConfig;
if (process.env.REACT_APP_ENV === 'production') {
  requestConfig = {
    baseUrl: 'https://api.doubletick.io',
    smsServiceUrl: 'https://getsmsotp.com',
    qsApiUrl: 'https://api.quicksell.co',
  };
} else if (process.env.REACT_APP_ENV === 'apiStaging') {
  requestConfig = {
    baseUrl: 'https://api-staging.doubletick.io',
    smsServiceUrl: 'https://getsmsotp.com',
    qsApiUrl: 'https://api.quicksell.co',
  };
} else if (process.env.REACT_APP_ENV === 'apiStaging2') {
  requestConfig = {
    baseUrl: 'https://api-staging-2.doubletick.io',
    smsServiceUrl: 'https://api-staging-2.doubletick.io',
    qsApiUrl: 'https://api.quicksell.co',
  };
} else if (process.env.REACT_APP_ENV === 'apiStaging3') {
  requestConfig = {
    baseUrl: 'https://api-staging-3.doubletick.io',
    smsServiceUrl: 'http://sms-service-staging.quicksell.co',
    qsApiUrl: 'https://api.quicksell.co',
  };
} else if (
  process.env.REACT_APP_ENV === 'stagingWithProd' ||
  process.env.REACT_APP_ENV === 'staging2WithProd'
) {
  requestConfig = {
    baseUrl: 'https://api.doubletick.io',
    smsServiceUrl: 'https://getsmsotp.com',
    qsApiUrl: 'https://api.quicksell.co',
  };
} else {
  requestConfig = {
    baseUrl: 'https://crm-3.dev.quicksell.co',
    smsServiceUrl: 'https://sms-service.dev.quicksell.co',
    qsApiUrl: 'https://api.quicksell.co',
  };
}

export default requestConfig;
