import { FC } from 'react';
import { IconProps } from '../app/typings';

const PlusIcon: FC<IconProps> = ({ color = '#fff', style, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={'20px'}
      viewBox="0 -960 960 960"
      width={'20px'}
      fill={color}
    >
      <path
        d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"
        fill={color}
      />
    </svg>
  );
};

export default PlusIcon;
