/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import BackButton from '../../components/BackButton';
import BillingDetails from './components/BillingDetails';
import BasicInfoInput from './components/BasicInfoInput';
import AddOrganisationHeader from './components/header';
import PlanSetup from './components/PlanSetup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../routes/auth-routes/routes';
import PlanBreakdown from './components/PlanBreakdown';
import { AddOrgSteps } from './types';
import { useSetAtom } from 'jotai';
import { newOrgBillingDetailsAtom, newOrgDetailsAtom } from './state';
import { getProflieAPI } from '../orgProfile/requests';
import { getCountryFromCountryCode, getDefaultCountry } from '../../utils/countries-mapper';
import useNotifications from '../../hooks/useNotifications';
import { useResetAtom } from 'jotai/utils';
import Loader from '../../components/loader';

const AddOrganisation = () => {
  const [currentStep, setCurrentStep] = useState<AddOrgSteps>('basic_info'); //add_credits
  const [createdOrgId, setCreatedOrgId] = useState<string | null>(null);
  const navigate = useNavigate();
  const { addNotification } = useNotifications()
  const setNewOrgDetails = useSetAtom(newOrgDetailsAtom);
  const setNewOnboardingBillingDetails = useSetAtom(newOrgBillingDetailsAtom);
  const resetNewOrgDetails = useResetAtom(newOrgDetailsAtom);
  const resetNewOnboardingBillingDetails = useResetAtom(newOrgBillingDetailsAtom);
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId')
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchOrgData = async () => {
      if (!orgId) return;
      setLoading(true);
      try {
        const orgDetails = await getProflieAPI({ orgId });
        const { city, countryCode, email, state, name, phone } = orgDetails.org;
        const { ownerName, billingDetails } = orgDetails;
        const country =
          getCountryFromCountryCode(countryCode) ?? getDefaultCountry();
        const symbol = country.symbol;
        const loginNumber = phone?.slice(symbol.length - 1);
        setNewOrgDetails({
          loginNumber,
          orgName: name,
          ownerFullName: ownerName,
          country,
        });

        setNewOnboardingBillingDetails({
          state,
          city,
          countryCode,
          email,
          pinCode: billingDetails?.pinCode,
          address: billingDetails?.billingAddress,
          billingTaxNumber: billingDetails?.billingTaxNumber ?? '',
        });
        if (
          !!state &&
          !!city &&
          !!email &&
          !!countryCode &&
          !!billingDetails?.pinCode &&
          !!billingDetails?.billingAddress
        ) {
          setCurrentStep('plan_setup');
        } else {
          setCurrentStep('billing_details');
        }
      } catch (error) {
        addNotification({
          message: (error as Error)?.message || 'Something went wrong',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    if (orgId) {
      setCreatedOrgId(orgId)
      fetchOrgData();
    }
  }, [orgId]);

  useEffect(() => {
    return () => {
      resetNewOnboardingBillingDetails()
      resetNewOrgDetails()
    }
  },[])

  const renderStep = () => {
    if (loading) return <Loader size={24} />;
    if (currentStep === 'basic_info') {
      return (
        <Box width={'600px'} margin={'auto'}>
          <BackButton onClick={() => navigate(ROUTES.organisationPage)} />
          <BasicInfoInput
            setCreatedOrgId={(orgId: string) => setCreatedOrgId(orgId)}
            handleNext={() => setCurrentStep('billing_details')}
          />
        </Box>
      );
    }
    if (currentStep === 'billing_details') {
      return (
        <Box width={'600px'} margin={'auto'}>
          <BackButton onClick={() => setCurrentStep('basic_info')} />
          <BillingDetails
            handleNext={() => setCurrentStep('plan_setup')}
            createdOrgId={createdOrgId!}
          />
        </Box>
      );
    }
    if (currentStep === 'plan_setup') {
      return (
        <>
          <BackButton onClick={() => setCurrentStep('billing_details')} />
          <PlanSetup />
        </>
      );
    }
  };

  const progress = useMemo(() => {
    // set progress based on current step
    if (currentStep === 'basic_info') {
      return 0;
    }
    if (currentStep === 'billing_details') {
      return 1;
    }
    if (currentStep === 'plan_setup') {
      return 2;
    }
    return 3;
  }, [currentStep]);

  if (currentStep === 'plan_setup') {
    return (
      <Box bgcolor={'var(--Neutral-Background)'}>
        <AddOrganisationHeader currentStep={progress} />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7} display={'flex'} justifyContent={'center'}>
            <Box mt={'60px'} width={'90%'}>
              {renderStep()}
            </Box>
          </Grid>
          <Grid item xs={12} lg={5}>
            <PlanBreakdown createdOrgId={createdOrgId ?? ''} />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box bgcolor={'var(--Neutral-Background)'}>
      <AddOrganisationHeader currentStep={progress} />
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display={'flex'}
          margin={'0 auto'}
          height={'100vh'}
          justifyContent={'center'}
        >
          <Box mt={'60px'}>{renderStep()}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddOrganisation;
