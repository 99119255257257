/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import { Box, Icon, Typography } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

interface EmptyDataMessageProps {
  message?: string;
  containerStyles?: SerializedStyles;
}

const EmptyDataMessage = ({
  message,
  containerStyles,
}: EmptyDataMessageProps) => {
  return (
    <Box
      css={css(
        `
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        column-gap: 8px;
        justify-content: center;
        align-items: center;
        row-gap: 4px;
        padding: 16px;
        color: #8484A8;
      `,
        containerStyles
      )}
    >
      <Icon>
        <ReportProblemIcon />
      </Icon>
      <Typography
        css={css`
          margin: 0px;
          font-family: Roboto, sans-serif;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5;
          text-align: center;
        `}
      >
        {message || `Something went wrong`}
      </Typography>
    </Box>
  );
};

export default EmptyDataMessage;
