const colors: { [key: string]: string } = {
  '0': '#c4616b',
  '1': '#c06382',
  '2': '#ae5fc0',
  '3': '#8959e5',
  '4': '#596bd7',
  '5': '#4d90cc',
  '6': '#317694',
  '7': '#3e9892',
  '8': '#57af5c',
  '9': '#7fa453',
};

function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }
  return hash;
}

export const getColorFromString = ({ str }: { str: string }) => {
  try {
    if (!str.length) return '#F0F4C3';

    const hash = hashCode(str);
    const index = Math.abs(hash) % 10;
    return colors[index.toString()];
  } catch (error) {
    return '#F0F4C3';
  }
};
