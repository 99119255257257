/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import DropdownComponent from '../../../../components/molecules/dropdown';
import { RATE_CHART_REGIONS } from '../../constants';
import { Close } from '@mui/icons-material';
import PlusIcon from '../../../../../icons/PlusIcon';
import MinusIcon from '../../../../../icons/MinusIcon';
import { CONVERSION_TYPE_LABELS, CONVERSION_TYPE_VALUES } from '../../types';
import { useCallback, useEffect, useState } from 'react';
import { getRateChartDetails, saveRateChartDetails } from '../../requests';
import useNotifications from '../../../../hooks/useNotifications';
import Loader from '../../../../components/loader';
import { useAtom } from 'jotai';
import { rateChartSelectedRegionAtom } from '../../states';

interface RateChartDetailsProps {
  onClose: () => void;
}

const RateChartDetails = ({ onClose }: RateChartDetailsProps) => {
  const [commissionDetails, setCommissionDetails] = useState<
    { value: number; type: CONVERSION_TYPE_VALUES }[]
  >([
    { type: CONVERSION_TYPE_VALUES.AUTHENTICATION, value: 0 },
    { type: CONVERSION_TYPE_VALUES.AUTHENTICATION_INTERNATIONAL, value: 0 },
    { type: CONVERSION_TYPE_VALUES.MARKETING, value: 0 },
    { type: CONVERSION_TYPE_VALUES.UTILITY, value: 0 },
  ]);
  const [rowsData, setRowsData] = useState<
    {
      type: CONVERSION_TYPE_VALUES;
      label: CONVERSION_TYPE_LABELS;
      dtPrice: number;
      yourPrice: number;
    }[]
  >([]);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [saveDetailsLoading, setSaveDetailsLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const orgId = urlParams.get('orgId') ?? '';
  const { addNotification } = useNotifications();
  const [selectedRegion, setSelectedRegion] = useAtom(
    rateChartSelectedRegionAtom
  );
  const [currentSelectedRegion, setCurrentSelectedRegion] =
    useState(selectedRegion);

  const fetchRateChartDetails = useCallback(
    async (country: string) => {
      try {
        setDetailsLoading(true);
        const response = await getRateChartDetails({ orgId, country });
        let rows: {
          type: CONVERSION_TYPE_VALUES;
          label: CONVERSION_TYPE_LABELS;
          dtPrice: number;
          yourPrice: number;
        }[] = Array.from(Object.keys(response.dtPrices)).map((key) => ({
          type: key as CONVERSION_TYPE_VALUES,
          label:
            CONVERSION_TYPE_LABELS[
              key.toUpperCase() as keyof typeof CONVERSION_TYPE_LABELS
            ],
          dtPrice: 0,
          yourPrice: 0,
        }));

        for (const [type, values] of Object.entries(response)) {
          for (const row of rows) {
            if (row.type in values) {
              if (type === 'dtPrices') {
                row.dtPrice = values[row.type];
              } else if (type === 'userPrices' && !!!values[row.type]) {
                row.yourPrice = row.dtPrice;
              } else {
                row.yourPrice = values[row.type];
              }
            }
          }
        }
        setRowsData(rows);
        setCommissionDetails((prev) =>
          prev.map((commissionDetails) => {
            for (let row of rows) {
              if (row.type === commissionDetails.type) {
                return {
                  ...commissionDetails,
                  value: Math.round(
                    ((row.yourPrice - row.dtPrice) / row.dtPrice) * 100
                  ),
                };
              }
            }
            return commissionDetails;
          })
        );
      } catch (error) {
        addNotification({
          type: 'error',
          message:
            (error as Error).message ?? 'Failed to get rate chart details',
        });
      } finally {
        setDetailsLoading(false);
      }
    },
    [addNotification, orgId]
  );

  const handleRegionChange = (value: string): void => {
    fetchRateChartDetails(value);
    setCurrentSelectedRegion(value);
  };

  useEffect(() => {
    fetchRateChartDetails(currentSelectedRegion);
  }, [fetchRateChartDetails, currentSelectedRegion]);

  const renderCommission = (type: CONVERSION_TYPE_VALUES) => {
    const commissionValue =
      commissionDetails.find((commission) => commission.type === type)?.value ??
      0;
    return (
      <Box
        css={css`
          display: flex;
          align-items: center;
          column-gap: 8px;
          width: 160px;
        `}
      >
        <IconButton
          css={css`
            border: 1px solid #e0e0e9;
            border-radius: 8px;
            padding: 5px;
          `}
          disabled={commissionValue === 0}
          onClick={() => {
            setCommissionDetails((prev) =>
              prev.map((commission) =>
                commission.type === type
                  ? { ...commission, value: Math.max(0, commission.value - 5) }
                  : commission
              )
            );
          }}
        >
          <MinusIcon color={commissionValue === 0 ? '#c9c9d1' : '#df5e57'} />
        </IconButton>
        <TextField
          sx={{
            '.MuiInputBase-root': {
              width: '70px',
              height: '32px',
              borderRadius: '8px',
              padding: '0px',
            },
            '.MuiInputBase-input': {
              textAlign: 'end',
              paddingLeft: '0px',
              marginLeft: '8px',
            },
          }}
          value={commissionValue}
          type="number"
          onChange={(event) => {
            event.target.value = event.target.value.replace(/^0+/, '');
            if (Number(event.target.value) > 100) return;
            setCommissionDetails((prev) =>
              prev.map((commission) =>
                commission.type === type
                  ? {
                      ...commission,
                      value: Number(event.target.value),
                    }
                  : commission
              )
            );
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                css={css`
                  margin-left: 4px;
                `}
              >
                <Typography
                  css={css`
                    font-size: 14px;
                    color: #2d2d2d;
                    margin-right: 16px;
                  `}
                >
                  %
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <IconButton
          css={css`
            border: 1px solid #e0e0e9;
            border-radius: 8px;
            padding: 5px;
          `}
          onClick={() => {
            setCommissionDetails((prev) =>
              prev.map((commission) =>
                commission.type === type
                  ? {
                      ...commission,
                      value: Math.min(100, commission.value + 5),
                    }
                  : commission
              )
            );
          }}
          disabled={commissionValue === 100}
        >
          <PlusIcon color={commissionValue === 100 ? '#c9c9d1' : '#3f906d'} />
        </IconButton>
      </Box>
    );
  };

  const handleSaveClick = async () => {
    try {
      setSaveDetailsLoading(true);
      const transformCommissionDetails = commissionDetails.reduce(
        (acc, { type, value }) => {
          acc[type] = !!!value ? 0 : value;
          return acc;
        },
        {} as Record<CONVERSION_TYPE_VALUES, number>
      );
      await saveRateChartDetails({
        orgId,
        ...transformCommissionDetails,
      });
      setSelectedRegion(currentSelectedRegion);
      addNotification({
        type: 'success',
        message: 'Updated rate chart details successfully',
      });
    } catch (error) {
      addNotification({
        type: 'error',
        message:
          (error as Error).message ||
          'Failed to save rate chart details. Please try again.',
      });
    } finally {
      setSaveDetailsLoading(false);
      onClose();
    }
  };

  return (
    <>
      <Box
        css={css`
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 4px 8px 4px 20px;
          border-bottom: 1px solid #e0e0e9;
        `}
      >
        <Typography
          css={css`
            font-size: 16px;
            font-weight: 600;
            color: #2d2d2d;
          `}
        >
          Configure markup charges
        </Typography>
        <Box
          css={css`
            display: flex;
            column-gap: 8px;
            align-items: center;
          `}
        >
          <Typography
            css={css`
              font-size: 14px;
              font-weight: 600;
              width: 100%;
            `}
          >
            Pricing for
          </Typography>

          <DropdownComponent
            options={Array.from(Object.values(RATE_CHART_REGIONS)).map(
              (region) => ({ name: region, value: region })
            )}
            sx={{
              '&.MuiFormControl-root': {
                width: '100%',
              },
              '.MuiInputBase-root .MuiSelect-select': {
                padding: '10px 12px',
              },
              '.MuiInputBase-root': {
                height: '32px',
                width: '100%',
                maxWidth: '120px',
                fontSize: '14px',
                fontWeight: '500',
                color: 'inherit',
                borderColor: '#E0E0EA',
                borderRadius: '4px',
                '&:hover': {
                  borderColor: '#2D2D2D',
                },
                '.MuiInputBase-formControl': {
                  border: 'none !important',
                  borderRadius: '0px',
                  '&:hover': {
                    border: 'none !important',
                    borderRadius: '0px',
                  },
                  '.Mui-focused': {
                    border: 'none !important',
                    borderRadius: '0px',
                  },
                },
              },
              '.Mui-focused': {
                border: '1px solid #3F906D !important',
              },
            }}
            containerProps={{
              sx: {
                width: '100%',
                maxWidth: '150px',
                minWidth: '125px',
              },
            }}
            renderValue={() => currentSelectedRegion}
            value={currentSelectedRegion}
            onChange={handleRegionChange}
            selectSx={{
              '.MuiSelect-select': {
                padding: '0px 32px 0px 12px !important',
                fontWeight: '500',
              },
              '.MuiSelect-icon': {
                fill: '#2d2d2d !important',
              },
            }}
            menuSx={{
              '.MuiMenu-list': {
                height: '200px',
                width: '250px',
                padding: '0px',
              },
              '.MuiButtonBase-root': {
                fontSize: '14px',
                fontWeight: '400',
                minHeight: 'auto',
                whiteSpace: 'normal',
                padding: '8px 12px',
                '&:first-of-type': {
                  '&.Mui-disabled': {
                    fontSize: '14px',
                    fontWeight: '400',
                    padding: '10px 12px 8px 12px',
                    borderBottom: '1px solid #E0E0EA',
                    opacity: 1,
                  },
                },
              },
            }}
            searchable
            placeholder="Search region"
            searchInputProps={{
              sx: {
                borderBottom: `1px solid #e0e0e9`,
              },
            }}
          />

          <IconButton
            css={css`
              &:hover {
                color: #2d2d2d;
              }
            `}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>

      <Box
        css={css`
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          padding: 20px;
          border-bottom: 1px solid #e0e0e9;
        `}
      >
        {detailsLoading ? (
          <Box
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 340px;
            `}
          >
            <Loader />
          </Box>
        ) : (
          <>
            <TableContainer
              component={Paper}
              css={css`
                box-shadow: none;
                border-top: 1px solid #e0e0e9;
                border-right: 1px solid #e0e0e9;
                border-left: 1px solid #e0e0e9;
              `}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      css={css`
                        background-color: #f7f7f8;
                        font-size: 14px;
                        font-weight: 600;
                        color: #2d2d2d;
                        padding: 8px 16px;
                        width: 160px;
                        border-right: 1px solid #e0e0e9;
                      `}
                      align="center"
                    >
                      Conversion Type
                    </TableCell>
                    <TableCell
                      css={css`
                        background-color: #f7f7f8;
                        font-size: 14px;
                        font-weight: 600;
                        color: #2d2d2d;
                        padding: 8px 16px;
                        width: 160px;
                        border-right: 1px solid #e0e0e9;
                      `}
                      align="center"
                    >
                      DoubleTick Price
                    </TableCell>
                    <TableCell
                      css={css`
                        background-color: #f7f7f8;
                        font-size: 14px;
                        font-weight: 600;
                        color: #2d2d2d;
                        padding: 8px 16px;
                        width: 160px;
                        border-right: 1px solid #e0e0e9;
                      `}
                      align="center"
                    >
                      Your Price
                    </TableCell>
                    <TableCell
                      css={css`
                        background-color: #f7f7f8;
                        font-size: 14px;
                        font-weight: 600;
                        color: #2d2d2d;
                        padding: 8px 16px;
                        width: 160px;
                      `}
                      align="center"
                    >
                      Commission
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsData.map((row) => {
                    const commissionValue =
                      commissionDetails.find(
                        (commission) => commission.type === row.type
                      )?.value ?? 0;

                    const calculatedYourPrice = (
                      row.dtPrice +
                      row.dtPrice * (commissionValue / 100)
                    ).toFixed(4);
                    return (
                      row.dtPrice && (
                        <TableRow
                          key={row.type}
                          css={css`
                            width: 160px;
                            height: 60px;
                          `}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            css={css`
                              font-size: 14px;
                              color: #585858;
                              padding: 8px 16px;
                              border-right: 1px solid #e0e0e9;
                            `}
                          >
                            {row.label}
                          </TableCell>
                          <TableCell
                            align="center"
                            css={css`
                              font-size: 14px;
                              color: #585858;
                              padding: 8px 16px;
                              border-right: 1px solid #e0e0e9;
                            `}
                          >
                            ₹{row.dtPrice.toFixed(4)}
                          </TableCell>
                          <TableCell
                            align="center"
                            css={css`
                              font-size: 14px;
                              font-weight: 600;
                              color: #3f906d;
                              padding: 8px 16px;
                              border-right: 1px solid #e0e0e9;
                            `}
                          >
                            ₹{calculatedYourPrice}
                          </TableCell>
                          <TableCell
                            align="center"
                            css={css`
                              padding: 8px 16px;
                            `}
                          >
                            {renderCommission(row.type)}
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography
              css={css`
                font-size: 14px;
                font-weight: 400;
                color: #7a7a7a;
              `}
            >
              The commission rate you choose will be uniformly applied across
              all currencies. For instance, setting it to 10% will ensure a 10%
              commission for each currency type.
            </Typography>
          </>
        )}
      </Box>

      <Box
        css={css`
          display: flex;
          justify-content: flex-end;
          column-gap: 12px;
          padding: 12px 20px;
        `}
      >
        <Button
          variant="outlined"
          css={css`
            background: #fbfbfc;
            border: 1px solid #e0e0e9;
            color: #2d2d2d;

            &:hover {
              border: 1px solid rgba(201, 201, 209, 0.9);
              background: rgba(0, 0, 0, 0.04);
            }
          `}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSaveClick}
          disabled={saveDetailsLoading}
        >
          {saveDetailsLoading ? <Loader size={20} /> : 'Save'}
        </Button>
      </Box>
    </>
  );
};

export default RateChartDetails;
